import { devRedirects, prodRedirects, Redirects } from "./redirectPages";
import { Redirect } from "../types/redirect";
import { initializeLDClient } from "./launchdarkly";

const getLocationSearch = () =>
  window.location.search.replace(/%3D/g, "=").replace(/%26/g, "&");

export const redirectToPage = async () => {
  const redirectPage = await getRedirectPage();
  if (redirectPage) {
    window.location.href = redirectPage + getLocationSearch();
  }
};

const getRedirectPage = async () => {
  const redirects = isDevEnvironment() ? devRedirects : prodRedirects;
  return await findRedirectPage(redirects);
};

const isDevEnvironment = () => {
  const { queryParams } = getQueryParams();
  return queryParams.get("funnel_mode") === "test";
};

const getQueryParams = () => {
  let urlSearch = getLocationSearch();
  let queryParams = new URLSearchParams(urlSearch);
  if (!queryParams.get("funnel_start")) {
    queryParams.set("funnel_start", "getstarted");
    urlSearch = queryParams.toString();
    window.history.replaceState(null, "", `?${urlSearch}`);
  }
  return { urlSearch, queryParams };
};

enum Plans {
  Individual = "Individual",
  Couple = "Couple",
}

const setTheRedirectByQueryParam = (queryParams: URLSearchParams) => {
  const subscription = queryParams.get("subscription");
  const initialAmount = queryParams.get("initial_amount");
  const price = queryParams.get("price");

  if (subscription) {
    if (subscription === Plans.Individual) {
      return Redirect.PRICING_INDIVIDUAL;
    } else if (subscription === Plans.Couple) {
      return Redirect.PRICING_COUPLE;
    }
  }

  if (initialAmount && price) {
    return Redirect.CHECKOUT;
  }
};

const findRedirectPage = async (redirects: Redirects) => {
  const isMobile = () => /Android|iPhone/i.test(navigator.userAgent);
  const { queryParams } = getQueryParams();

  let funnelStart = queryParams.get("funnel_start") || "NA";
  let primary_member_id = queryParams.get("primary_member_id") || "NA";
  let uuid = queryParams.get("uuid") || "NA";
  const email = queryParams.get("email") || "NA";
  const utmSource = queryParams.get("utm_source" || "NA");

  let redirect = Redirect.PRICING;

  if (utmSource === Redirect.USER_RESEARCH) {
    redirect = Redirect.USER_RESEARCH;
  } else if (funnelStart === "partner_activation" || primary_member_id !== "NA") {
    if (isMobile()) {
      redirect = Redirect.NATIVE_APP;
    }else {
      redirect = Redirect.WEBAPP_HOME;
    }
  } else if (funnelStart === "pricing") {
    redirect = Redirect.CHECKOUT;
  }

  if (redirect === Redirect.PRICING) {
    const client = await initializeLDClient(uuid, email);

    if (client) {
      try {
        const show_ty_page = client.variation("show_ty_page");

        if (show_ty_page) {
          redirect = Redirect.THANK_YOU;
        } else {
          redirect = setTheRedirectByQueryParam(queryParams) || redirect;
        }
      } catch (error) {
        redirect = setTheRedirectByQueryParam(queryParams) || redirect;
      } finally {
        await client.close();
      }
    } else {
      redirect = setTheRedirectByQueryParam(queryParams) || redirect;
    }
  }

  return redirects[redirect];
};
